import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'

const HomePage = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <h1>Home</h1>
          <h2>Simple Sound Studio is a Multimedia Production facility for the budget aware artist or enterprise.</h2>
          <h2>Address</h2>
          <p>Drabstraat 24A<br />
          9260 Schellebelle (BELGIUM)<br />
          Tel.: +32 (0)485 28 65 11<br />
          Ondernemingsnummer: BE 830.697.310<br />
          Bedrijfsleider: Peter Van Acker</p>

          <h2>Our main features:</h2>
          <ul>
              <li>Multi-track audio recording</li>
              <li>32 track live recording on site</li>
              <li>Web design using Html, REACT, JavaScript, NodeJS, etc... for small sites</li>
              <li>Graphic cover design</li>
              <li>Mixing</li>
              <li>Mastering</li>
              <li>Producing</li>
          </ul>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default HomePage;
