import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import MicIcon from '@mui/icons-material/Mic';
import PeopleIcon from '@mui/icons-material/People';
import BlenderIcon from '@mui/icons-material/Blender';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import LinkIcon from '@mui/icons-material/Link';
import AlbumIcon from '@mui/icons-material/Album';
import WidgetsIcon from '@mui/icons-material/Widgets';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

//////////////////////////////////////////////////////////////////////////////////////////////////
// Define the possible menu items to point to a route specified under <Routes> in the Startpage
// items of type 'mainMenu' will apear as the first list
// items of type 'secondaryMenu' will apear as the second list

const navRoutes = [
  {
    type: "mainMenu",
    path: '/',
    sidebarName: 'Home',
    icon: <HomeIcon />
  },
  {
    type: "mainMenu",
    path: '/Recording',
    sidebarName: 'Recording',
    icon: <MicIcon />
  },
  {
    type: "mainMenu",
    path: '/Mixing',
    sidebarName: 'Mixing',
    icon: <BlenderIcon />
  },
  {
    type: "mainMenu",
    path: '/Mastering',
    sidebarName: 'Mastering',
    icon: <AlbumIcon />
  },
  {
    type: "mainMenu",
    path: '/Production',
    sidebarName: 'Production',
    icon: <WidgetsIcon />
  },
  {
    type: "mainMenu",
    path: '/Music',
    sidebarName: 'Music',
    icon: <LibraryMusicIcon />
  },
  {
    type: "secondaryMenu",
    path: '/Links',
    sidebarName: 'Links',
    icon: <LinkIcon />
  },
  {
    type: "secondaryMenu",
    path: '/Contact',
    sidebarName: 'Contact',
    icon: <PeopleIcon />
  },
  {
    type: "secondaryMenu",
    path: '/News',
    sidebarName: 'News',
    icon: <NewReleasesIcon />
  },
];


export default navRoutes;