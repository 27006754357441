import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Copyright(props) {
    return (
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="www.ssst.be/">
              Created by Peter Van Acker @ Simple Sound Studio
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Paper>
      </Grid>
    );
  }
  
  export default Copyright;  