import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'

import underconstruction from "../images/underconstruction.gif"

const Production = () => {
    return (
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <h1>Production</h1>
          <img src={underconstruction} alt="Under construction" />
        </Paper>
      </Grid>
    );
};

export default Production;
